var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "oxy-snap-page" }, [
    _c("div", { staticClass: "video-box" }, [
      _c("video", {
        attrs: {
          controls: "",
          autoplay: "",
          width: "100%",
          height: "100%",
          "object-fit": "fill",
          src: _vm.videoList[_vm.selectIndex].url,
          playsinline: "",
          "webkit-playsinline": "",
          poster: _vm.ossHostPond + "img/oxysnap_tutorial_bg.png"
        }
      })
    ]),
    _c("div", { staticClass: "video-title" }, [
      _vm._v(
        " " +
          _vm._s(_vm.selectIndex + 1) +
          "/3 " +
          _vm._s(_vm.videoList[_vm.selectIndex].title) +
          " "
      )
    ]),
    _c("div", { staticClass: "btn-list" }, [
      _c(
        "div",
        {
          staticClass: "btn-item",
          on: {
            click: function($event) {
              return _vm.onclickChangeVideo(0)
            }
          }
        },
        [_vm._v("上一个")]
      ),
      _c(
        "div",
        {
          staticClass: "btn-item",
          on: {
            click: function($event) {
              return _vm.onclickChangeVideo(1)
            }
          }
        },
        [_vm._v("下一个")]
      )
    ]),
    _c(
      "div",
      { staticClass: "bottom-box" },
      [
        _c("img", {
          attrs: { src: _vm.ossHostPond + "img/oxysnap_bottom.png", alt: "" }
        }),
        _c(
          "wx-open-launch-weapp",
          {
            staticClass: "openBtn",
            attrs: {
              id: "launch-btn",
              username: "gh_17df9530faf3",
              path: "main/pages/activity/categoryReport/index"
            }
          },
          [
            _c("script", { attrs: { type: "text/wxtag-template" } }, [
              _vm._v(
                ' <div style="width:100%;text-align:center;line-height:50px;color:#fff;font-size:20px;">查看更多智能设备</div> '
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }