(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.oxySnap = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  var _default = {
    name: "OxySnap",
    data: function data() {
      var ossHostPond = this.$config.base.ossHostPond;
      return {
        wxInfo: {},
        ossHostPond: ossHostPond,
        // 当前视频
        selectIndex: 0,
        // 视频列表
        videoList: [{
          url: "".concat(ossHostPond, "video/oxysnap_tutorial.mp4"),
          title: '测量及注意事项'
        }, {
          url: "".concat(ossHostPond, "video/oxysnap_oxygen_calibration.mp4"),
          title: '饱和氧校准流程'
        }, {
          url: "".concat(ossHostPond, "video/oxysnap_zoxygen_calibration.mp4"),
          title: '零氧校准流程'
        }]
      };
    },
    created: function created() {
      var vm = this;
      vm.weixinInit();
    },
    methods: {
      // 获取微信配置
      weixinInit: function weixinInit() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "https://m.yudada.com/jssdk/configuration?url=".concat(location.href)
        }).then(function (res) {
          var _res$data = res.data,
              appId = _res$data.appId,
              nonceStr = _res$data.nonceStr,
              signature = _res$data.signature,
              timestamp = _res$data.timestamp;
          vm.wxInfo = res.data;
          window.wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: ['wx-open-launch-weapp'],
            openTagList: ['wx-open-launch-weapp']
          });
        });
      },
      // 切换视频
      onclickChangeVideo: function onclickChangeVideo(type) {
        var vm = this;

        if (type == 0) {
          if (!vm.selectIndex <= 0) {
            vm.selectIndex--;
          }
        }

        if (type == 1) {
          if (vm.selectIndex < vm.videoList.length - 1) {
            vm.selectIndex++;
          }
        }
      }
    }
  };
  _exports.default = _default;
});